<template>
  <div class="boolv-modal">
    <a-modal :visible="modalVisible" @ok="handleOk" @cancel="handleCancel" :content="content" :title="title"
      :width="width" class="modal-class" :style="modalStyle" :ok-button-props="okButtonProps"
      :cancel-button-props="cancelButtonProps" :transition-name="''" :closeIcon="closeIcon"
      :bodyStyle="bodyStyle" :zIndex="zIndex" :footer="showFooter ? undefined : null" centered>
      <slot></slot>
    </a-modal>
  </div>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import CloseIcon from './closeIcon.vue'
const props = defineProps({
  visible: Boolean,
  width: String,
  title: String,
  showFooter: String,
  okButtonProps: Object, //按钮配置参考 https://antdv.com/components/button
  cancelButtonProps: Object,
  modalStyle: Object,
  content: String,
  bodyStyle: Object,
  zIndex: Number
})

const emit = defineEmits(['cancel'])

const modalVisible = ref(props.visible);

watch(() => props.visible, () => {
  modalVisible.value = props.visible
});

const closeIcon = ref(<CloseIcon />);

const handleOk = () => {
  modalVisible.value = false;
};
const handleCancel = () => {
  emit('cancel')
  modalVisible.value = false;
};

</script>
<style lang="less" scoped>
:global(.modal-class .ant-modal-content) {
  border-radius: 4px;
}

:global(.modal-class .ant-modal-body) {
  display: flex;
  padding: 0;
}
</style>